<template>
	<v-row justify="center">
		<v-col cols="12" md="6">
			<v-dialog v-model="dialog" width="auto">
				<v-card align="center" class="background">
					<v-card-title>
						<v-btn absolute top right title="zamknij" icon @click="dialog = false">
							<v-icon dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-row justify="center" align="center" class="pt-6 pt-md-0">
						<div>
							<v-col>
								<v-img class="ml-sm-2" lazy-src="https://picsum.photos/id/1073/480/280" :src="product.imageThumbnail" max-height="480px" max-width="280px"> </v-img>
								<v-col>
									<v-btn class="text-uppercase buy-button" color="green" :href="product.link">
										<v-icon left> mdi-cart-arrow-down </v-icon>
										Dodaj Do Koszyka
									</v-btn>
								</v-col>
							</v-col>
						</div>
						<v-divider class="mb-10 d-none d-md-flex" vertical></v-divider>
						<v-col>
							<v-card-text v-html="product.description"> </v-card-text>
						</v-col>
					</v-row>
				</v-card>
			</v-dialog>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: "Modal",
	props: {
		id: String,
		dialogVisible: Boolean,
	},
	data() {
		return {};
	},
	computed: {
		currentSize() {
			return this.$vuetify.breakpoint.smOnly ? "MobileLayout" : "DesktopLayout";
		},
		product() {
			return this.$store.getters.product(this.id);
		},
		dialog: {
			get: function () {
				return this.dialogVisible;
			},
			set: function (value) {
				if (!value) {
					this.$emit("close");
				}
			},
		},
	},
};
</script>
<style lang="scss" scoped>
.buy-button {
	color: white;
	border: 0 solid;
	box-shadow: inset 0 0 20px rgba(50, 156, 1, 0.884);
	outline: 1px solid;
	outline-color: rgba(52, 255, 1, 0.219);
	outline-offset: 0px;
	text-shadow: none;
	-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	outline-color: rgba(0, 255, 42, 0.185);
	outline-offset: 0px;

	&:hover {
		border: 1px solid;
		box-shadow: inset 0 0 20px rgba(231, 235, 6, 0.719), 0 0 20px rgba(133, 250, 0, 0.589);
		outline-offset: 15px;
		outline-color: rgba(52, 185, 12, 0);
		text-shadow: 1px 1px 2px #0ed45a;

		& i.v-icon {
			&.v-icon {
				outline-color: black;
			}
		}
	}
}
.background {
	background: rgb(246, 249, 254); /* Old browsers */
	background: -moz-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, rgba(246, 249, 254, 1) 5%, rgba(226, 240, 254, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f6f9fe', endColorstr='#e2f0fe',GradientType=0 ); /* IE6-9 */
}

.btnfos2 {
	cursor: pointer;
	max-width: 100px;
	width: 100%;
}

.btnfos-2 {
	border: 0 solid;
	box-shadow: inset 0 0 20px rgba(156, 94, 1, 0.884);
	outline: 1px solid;
	outline-color: rgba(236, 145, 9, 0.829);
	-webkit-outline-radius: 5px;
	text-shadow: none;
	-webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
	outline-color: rgba(247, 183, 7, 0.863);
	outline-offset: 0px;

	&:hover {
		border: 1px solid;
		box-shadow: inset 0 0 20px rgba(250, 172, 5, 0.87), 0 0 20px rgba(255, 188, 3, 0.589);
		outline-offset: 15px;
		outline-color: rgba(5, 255, 130, 0);
		text-shadow: 1px 1px 2px #427388;
	}
}
</style>